import axios from "axios"
import router from './router'
import store from './store'

//自动添加网页前缀
// axios.defaults.baseURL = window.location.protocol + "//" + window.location.hostname + ":8081";
axios.defaults.baseURL = "https://zktl.top";
// axios.defaults.baseURL = "https://test.zktl.top";
//
// 请求拦截
axios.interceptors.request.use(config => {
    return config
})

// 响应拦截
axios.interceptors.response.use(response => {
    let code = response.data.code;
    if (code === 200) {
        return response
    } else {
        if (code === 401) {
            router.push("/login").catch(err => {});
            store.commit("REMOVE_INFO");
        }
        return Promise.reject(response.data.msg)
    }
},error => {
    if (error.response.status === 500){
        return Promise.reject(error.response.data)
    }
    if (error.response && error.response.data) {
        let code = error.response.data.code;
        if (code === 401) {
            router.push("/login").catch(err => {});
            store.commit("REMOVE_INFO");
        }
        return Promise.reject(error.response.data.msg)
    }
})