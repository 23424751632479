<template>
    <div class="login">
        <div class="login_form">
            <p>臻科机车管理系统 v2.0</p>
            <el-tabs v-model="activeName">
                <el-tab-pane label="密码登录" name="first">
                    <el-form :model="loginForm" :rules="rules" ref="loginForm">
                        <el-form-item label="" prop="userName" class="elItem">
                            <el-input type="text" autocomplete="off" v-model="loginForm.userName" prefix-icon="el-icon-user-solid" placeholder="请输入账号"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="password">
                            <el-input type="password" @keyup.enter.native="submitForm" autocomplete="off" v-model="loginForm.password" prefix-icon="el-icon-lock" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <el-form-item class="btns">
                            <el-button type="primary" @click="submitForm">登录</el-button>
                            <el-button @click="resetLoginForm">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="短信登录" name="second">
                    <el-form :model="smsLoginForm" :rules="smsRules" ref="smsLoginForm">
                        <el-form-item label="" prop="phoneNumber" class="elItem">
                            <el-input type="text" autocomplete="off" v-model="smsLoginForm.phoneNumber" prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号码"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="verificationCode" class="input-with-button">
                            <el-input type="text" autocomplete="off" v-model="smsLoginForm.verificationCode" prefix-icon="el-icon-chat-round" placeholder="请输入验证码"></el-input>
                            <el-button type="info" @click="getCode" size="mini">{{buttonText}}</el-button>
                        </el-form-item>
                        <el-form-item class="btns">
                            <el-button type="primary" @click="submitSmsForm">登录</el-button>
                            <el-button @click="resetLoginForm">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!--        备案标识符-->
        <div class="copyright">
            版权©所有：四川臻科铁路机车有限公司（2018-2024)
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ICP备：<a href="https://beian.miit.gov.cn/?wm=vt1#/Integrated/recordQuery">蜀ICP备2021020210号-1</a>
        </div>
    </div>
</template>

<script>
export default {
    name:"login",
    data() {
        return {
            coolDownTime: 120,
            countdown: 0,
            buttonText: "获取验证码",
            loginForm: {
                userName: "",
                password: "",
                userType: 0,
            },
            smsLoginForm: {
                phoneNumber: "",
                verificationCode: "",
                userType: 0,
            },
            activeName:'first',
            rules: {
                userName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ],
            },
            smsRules: {
                phoneNumber: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' }
                ],
                verificationCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 6, max:6, message: '请输入6位验证码', trigger: 'blur' }
                ],
            },
        };
    },
    methods: {
        // TODO:密码登录
        submitForm() {
            this.$refs["loginForm"].validate((valid) => {
                if (valid) {
                    this.$axios.post("/sys_user/login", this.loginForm).then((res) => {
                        let token = res.data.data.token;
                        this.$store.commit("SET_TOKEN", token);
                        this.$message.success("登录成功");
                        this.countdown = 0;
                        this.coolDownTime = 0;
                        this.$router.push("/index");
                    }).catch((err) => {
                        this.$message.error(err);
                    });
                } else {
                    return false;
                }
            });
        },
        // TODO:短信登录
        submitSmsForm() {
            this.$refs["smsLoginForm"].validate((valid) => {
                if (valid) {
                    this.$axios.post("/sys_user/smsLogin", this.smsLoginForm).then((res) => {
                        let token = res.data.data.token;
                        this.$store.commit("SET_TOKEN", token);
                        this.$message.success(res.data.msg);
                        this.countdown = 0;
                        this.coolDownTime = 0;
                        this.lastRequestTimestamp = 0;
                        this.$router.push("/index");
                    }).catch((err) => {
                        this.$message.error(err);
                    });
                } else {
                    return false;
                }
            });
        },
        resetLoginForm() {
            this.$refs["loginForm"].resetFields();
            this.$refs["smsLoginForm"].resetFields();
        },
        getCode() {
            if (!this.smsLoginForm.phoneNumber || this.smsLoginForm.phoneNumber.length !== 11) {
                this.$message.error("请输入11位手机号码");
                return;
            }

            // 检查是否在冷却时间内
            let currentTime = new Date().getTime();
            if (currentTime - this.lastRequestTimestamp < this.coolDownTime * 1000) {
                this.$message.error("请在120秒后再次尝试获取验证码");
                return false;
            }

            // 更新最后请求时间戳和倒计时
            this.lastRequestTimestamp = currentTime;
            this.countdown = this.coolDownTime;

            // 启动倒计时
            this.$axios.post("/smsCode", this.smsLoginForm).then((res) => {
                this.$message.success(res.data.msg)
                this.countdownTimer = setInterval(() => {
                    this.countdown--;
                    if (this.countdown <= 0) {
                        clearInterval(this.countdownTimer);
                        this.buttonText = "获取验证码";
                    } else {
                        this.buttonText = `重发(${this.countdown})`;
                    }
                }, 1000);
            }).catch(err => {
                this.countdown = 0;
                this.coolDownTime = 0;
                this.$message.error(err)
            });
        },
    },
};
</script>

<style scoped lang='less'>
.login {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/demo-1-bg.jpg");//背景图
    background-size: 100% 100%;
    background-position: center center;
    overflow: auto;
    position: relative;
    .login_form {
        width: 400px;
        position: absolute;
        left: 75%;
        top: 50%;
        margin-left: -180px;
        margin-top: -150px;
        padding: 10px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px #ddd;
        .btns {
            display: flex;
            justify-content: flex-end;
        }
    }
    p {
        font-size: 24px;
        text-align: center;
        font-weight: 550;
    }
}
.input-with-button .el-input {
    position: relative;
}

.input-with-button .el-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.copyright{
    position: fixed;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 15px;
    color: #FFFFFF;
}
.copyright a{
    color: #FFFFFF;
}
</style>